<template>
  <div>
    <el-result title="404" subTitle="抱歉，路径错误">
  <template slot="icon">
    <el-image :src="img"></el-image>
  </template>
  <template slot="extra">
    <h2>{{timer}}秒后跳转到首页</h2>
  </template>
</el-result>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: 3,
      img: require("../../assets/image/404.png")
    };
  },
  mounted() {
    this.cutdown();
  },
  methods: {
    cutdown() {
      setInterval(() => {
        this.timer--;
        if (this.timer == 0) {
          this.$router.push("/");
        }
      }, 1000);
    }
  }
};
</script>

<style  scoped>
</style>